<template>
	<div class="w-full">
		<div v-for="(element, elementKey) in sortedObjects" :key="element.id" class="w-full">
			<div class="flex flex-col justify-start w-full">
				<div class="flex flex-row justify-start items-center w-full">
					<span
						v-if="level > 1"
						class="mx-1 flex flex-col items-center justify-center"
						:style="{
							width: (level > 1 ? 25 * (level - 1) : 25 * level) + 'px',
						}"
					>
						<FontAwesomeIcon :icon="faTurnDownRight" class="h-5 w-5 text-black"></FontAwesomeIcon>
					</span>
					<div
						class="flex flex-row justify-between h-8 rounded pl-2 pr-3 font-normal hover:font-bold my-1 cursor-pointer w-full"
						:class="{
							'bg-primary-800': selectedVariable !== currentVariableAddress(elementKey.toString()) || selectedVariableGroup != variableGroup,
							'bg-primary-600': selectedVariable === currentVariableAddress(elementKey.toString()) && selectedVariableGroup == variableGroup,
						}"
						@click="emits('click', (variableAddress ? variableAddress + '.' : '') + elementKey, element.attributes.group)"
					>
						<div class="flex items-center text-white w-full pointer-events-none justify-between select-none">
							<div>
								<FontAwesomeIcon v-if="element.attributes.group" :icon="faFolder" class="mr-2"></FontAwesomeIcon>
								<span class="text-lg"> {{ element.name }}</span>
							</div>
							<div v-if="!element.attributes.group">
								<FontAwesomeIcon v-if="element.attributes.system && !element.attributes.group" :icon="faLockAlt" class="mr-2 text-grey-500 font-semibold align-text-bottom pb-px"></FontAwesomeIcon>
								<FontAwesomeIcon v-if="element.attributes.list && !element.attributes.group" :icon="faSquareList" class="mr-2 text-grey-500 font-semibold align-text-bottom"></FontAwesomeIcon>
								<span class="text-sm text-grey-500 font-semibold align-bottom"> {{ element.type ? (element.type.toString() === ActivityTemplateVariableType.DATETIME ? 'DT' : element.type.toString().charAt(0).toUpperCase()) : '' }}</span>
							</div>
						</div>
						<div v-if="element.attributes.group" class="flex items-center text-white select">
							<FontAwesomeIcon
								:icon="faCaretUp"
								class="transition-all transform duration-75"
								:class="{
									'rotate-180': openGroups.includes(variableGroup + '.' + currentVariableAddress(elementKey.toString())),
								}"
							></FontAwesomeIcon>
						</div>
					</div>
				</div>
				<div v-if="element.attributes.group && openGroups.includes(variableGroup + '.' + currentVariableAddress(elementKey.toString()))">
					<VariableSelector
						v-if="Object.keys(element.children).length > 0"
						:objects="element.children"
						:item-key="itemKey"
						:level="level + 1"
						:folder-name="element.name"
						:variable-group="variableGroup"
						:variable-address="(variableAddress ? variableAddress + '.' : '') + elementKey + '.children'"
						:selected-variable="selectedVariable"
						:selected-variable-group="selectedVariableGroup"
						:open-groups="openGroups"
						:can-create="canCreate"
						@click="(variableAddressClicked: string, isGroup: boolean) => emits('click', variableAddressClicked, isGroup)"
					/>
					<div v-else class="flex flex-row justify-start items-center w-full">
						<span
							class="mx-1 flex flex-col items-center justify-center"
							:style="{
								width: (level > 1 ? 25 * (level - 1) : 25 * level) + 'px',
							}"
						>
							<FontAwesomeIcon :icon="faTurnDownRight" class="h-5 w-5 text-black"></FontAwesomeIcon>
						</span>
						<div class="flex flex-row justify-between items-center h-8 bg-gray-200 rounded px-3 font-normal my-1.5 cursor-pointer w-full">
							<span> No variables in this folder... yet</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { computed, defineEmits, defineProps, withDefaults } from 'vue';
	import { ActivityTemplateVariableType, ActivityVariables } from '@/types/activityTemplate';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faCaretUp, faFolder, faLockAlt, faSquareList } from '@fortawesome/pro-regular-svg-icons';
	import { faTurnDownRight } from '@fortawesome/pro-light-svg-icons';

	interface Props {
		objects: ActivityVariables;
		itemKey: string;
		level?: number;
		variableAddress?: string;
		variableGroup: string;
		selectedVariable: string;
		selectedVariableGroup: string | undefined;
		folderName?: string | boolean;
		openGroups: string[];
		canCreate?: boolean;
	}

	const props = withDefaults(defineProps<Props>(), {
		itemKey: 'id',
		level: 1,
		variableAddress: '',
		folderName: false,
		canCreate: true,
	});

	const sortedObjects = computed<ActivityVariables>(() => {
		return props.objects;
		//props.objects.sort((a: ActivityTemplateVariables, b: ActivityTemplateVariables) => (b?.group ?? false) - (a?.group ?? false));
	});

	const emits = defineEmits<{
		(e: 'click', address: string, group: boolean): void;
		(e: 'create', address: string | boolean): void;
	}>();

	const currentVariableAddress = (variableIndex: string) => {
		return (props.variableAddress ? props.variableAddress + '.' : '') + variableIndex;
	};
</script>
